<script setup lang="ts">
const { isEventTheme } = useEventsCollector();
const logoIcon = computed(() => (isEventTheme.value ? "146/black-friday-logo-icon" : "146/logo"));
</script>

<template>
	<NuxtIcon :name="logoIcon" class="black-friday-logo" filled />
</template>

<style lang="scss" scoped>
.black-friday-logo {
	&:deep(.neon) {
		animation: neon linear infinite 2s;
	}
	&:deep(.blink) {
		animation: blink linear infinite 2s;
	}
}
@keyframes neon {
	20% {
		opacity: 1;
	}
	80% {
		opacity: 0.6;
	}
}
@keyframes blink {
	70% {
		opacity: 1;
	}
	79% {
		opacity: 0;
	}
	81% {
		opacity: 1;
	}
	82% {
		opacity: 0;
	}
	83% {
		opacity: 1;
	}
	92% {
		opacity: 0;
	}
	92.5% {
		opacity: 1;
	}
}
</style>
